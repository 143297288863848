/* .about-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-container p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 15px;
  } */


  .container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: rgba(255, 255, 255, 0.593); /* Semi-transparent white background */
    border-radius: 10px; /* Rounded corners for the container */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
  }
  
  .container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333; /* Text color */
  }
  
  .container h4 {
    align-items: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333; /* Text color */
  }

  .container p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #555; /* Text color */
  }